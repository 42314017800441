@import 'styles/layers.scss';

@layer uikit {
  .wrap {
    :global {
      a {
        text-decoration-line: underline;
      }
      a, :active, a:visited {
        color: var(--white);
      }
      color: var(--white);
    }
  }
  
  .ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
