@import 'styles/layers.scss';

@layer component {
  .content {
    padding-right: 20px;
    border-right: 1px solid var(--black11);
  }

  .wrapper {
    min-height: 414px;
    width: 100%;
  }
}
