@import 'styles/layers.scss';

@layer component {
  .title {
    font-size: 14px;
    margin: 0 0 20px;
  }
  
  .block {
    margin-right: 70px;
  }
  
  .group {
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: repeat(4, 1fr);
    width: 385px;
    row-gap: 6px;
  }

  @media screen and (max-width: 568px) {
    .title {
      font-size: 16px;
    }
    .block {
      margin-right: 0;
    }
    .blocks {
      flex-direction: column;
    }
  }
}