@import 'styles/layers.scss';

@layer uikit {
  .link {
    color: inherit;
    &:hover, &:active, &:visited {
      color: inherit;
    }
    font-size: 12px;
  }

  .h1 {
    font-size: 20px;
    margin: 24px 0 16px;
  }

  .h2 {
    font-size: 16px;
    margin: 20px 0 12px;
  }

  .h3 {
    font-size: 14px;
    margin: 16px 0 8px;
  }

  .h4 {
    font-size: 12px;
    margin: 12px 0 8px;
  }

  .h5 {
    font-size: 12px;
    margin: 12px 0 8px;
  }

  .h6 {
    font-size: 12px;
    margin: 12px 0 8px;
    font-style: italic;
  }

  .p {
    line-height: 190%;
    margin: 0 0 12px 0;
    padding: 0;
    font-size: 12px;
  }

  .ul {
    list-style-type: disc;
  }

  .li {
    margin: 0 0 4px 0;
    font-size: 12px;
  }

  .table {
    width: fit-content;
    margin: 0;
    font-size: 12px;
  }

  .table-wrap {
    margin: 12px 0;
    overflow-x: auto;
  }

  .table, .th, .td{
    border: 1px solid var(--black22);
  }

  .th {
    border-bottom: 2px solid var(--black22);
  }

  .th, .td{
    padding: 8px 10px;
  }

  .hr {
    opacity: 1;
    border-color: var(--black28);
    margin: 24px 0;
  }

  .img {
    max-width: 700px;
    height: auto;
  }

  .code, .blockquote {
    margin: 8px 0 12px;
  }

  .code-plugin, .code, .blockquote {
    font-size: 12px !important;
  }

  .code {
    display: block;
    overflow-x: auto;
    background: rgb(29, 31, 33);
    color: rgb(197, 200, 198);
    padding: 0.5em;
    font-size: 12px;
  }

  .pre {
    margin: 0 0 12px;
  }
}
